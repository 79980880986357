@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap');
@import url('https://fonts.googleapis.com/css2?family=STIX+Two+Math&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Uchen&display=swap');

.ff-1 {
    font-family: 'Fredericka the Great', cursive;
}

.ff-2 {
    font-family: 'STIX Two Math', serif;
}

.ff-3 {
    font-family: 'Architects Daughter', cursive;
}

.ff-4 {
    font-family: 'Uchen', serif;
}

.ff- {}

.ff- {}

.ff- {}

.cover {
    object-fit: cover;
}

.z-1000 {
    z-index: 1000;
}

* {
    text-decoration: none !important;
    outline: none !important;
}

a {
    color: inherit;
}

.appBar {
    background-color: rgb(57 57 57 / 63%) !important;
    color: black !important;
    box-shadow: none !important;
}

.nav-item {
    color: white;
    text-decoration: none;
    font-size: large;
    margin-left: 50px;
    transition: 0.1s;
    margin-top: 12px;
    cursor: pointer;
}

.nav-item:hover {
    color: white;
    transform: scale(1.1);
    border-bottom: 2px solid rgb(255, 255, 255);
}

.nav-item-m {
    color: black;
    font-size: larger;
    padding: 8px;
}

.nav-item-m:hover {
    background-color: rgb(56, 56, 56);
    color: white;
}

.active {
    transform: scale(1.1);
    border-bottom: 2px solid rgb(255, 255, 255);
}

.startBild {
    width: 100%;
    height: 600px;
    object-fit: cover;
    filter: brightness(0.7);
    z-index: -1;
}

.willkommText {
    background-color: #0000009c;
    padding: 30px;
    color: white;
    z-index: 1000;
    align-items: center;
}

.speiseButton {
    margin: auto;
    border: none;
    padding: 10px;
    box-shadow: 0px 3px 6px 0px black;
    color: black;
    background-color: white;
    transition: 0.2s;
}

.speiseButton:hover {
    background-color: #212121;
    color: white;
}

/* .indexBildDiv {
    padding: 50px;
    background-image: url(./image/3.jpg);
    background-position: center;
    background-size: cover;
    filter: brightness(0.6);
} */

.hertDiv {
    padding: 50px;
    background: rgb(0, 0, 0);
    background: linear-gradient(353deg, rgb(56, 56, 56) 55%, rgba(255, 255, 255, 1) 55%);
}

.hertDiv2 {
    background: rgb(0, 0, 0);
    background-image: linear-gradient(190deg, rgb(56, 56, 56) 55%, rgba(255, 255, 255, 1) 55%)
}

.herz {
    animation: heartbeat 2.2s infinite;
}

@keyframes heartbeat {
    0% {
        transform: scale(0.8);
    }

    5% {
        transform: scale(0.9);
    }

    10% {
        transform: scale(0.8);
    }

    15% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.8);
    }

    100% {
        transform: scale(0.8);
    }
}

.footer-item {
    font-size: small;
    transition: 0.1s;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
}

.footer-item:hover {
    transform: scale(1.15);
    color: gray;
}

.card {
    transition: 0.3s;
}

.card:hover {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    transform: scale(1.01);
}

.kontaktInput {
    padding: 20px 15px;
    border: 1px solid rgb(228, 228, 228);
    box-shadow: none;
}

.sendenButton {
    margin: auto;
    border: 1px solid rgb(228, 228, 228);
    padding: 10px;
    _box-shadow: 0px 3px 6px 0px black;
    color: black;
    background-color: white;
    transition: 0.2s;
}

.sendenButton:hover {
    background-color: #212121;
    color: white !important;
}

@media screen and (max-width: 400px) {
    .untenText {
        font-size: 7pt !important;
    }

    .mobile-text {
        font-size: small;
    }
}

.erro .MuiSnackbarContent-root {
    background-color: #f50057;
}

.info .MuiSnackbarContent-root {
    background-color: #31d887;
}

.nachOben {
    position: fixed !important;
    z-index: 100 !important;
    bottom: 10px !important;
    right: 10px !important;
    border: none;
    background-color: rgb(245, 0, 87);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    color: white;
    width: 50px;
    height: 50px;
}